import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

const pageMeta = {
    title: `KEYNOTE-091: Most Common Adverse Reactions`,
    keywords: `keynote-091 adverse reactions`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions associated with the KEYNOTE-091 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-adjuvant-therapy/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nsclc-adjuvant-therapy/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE⁠-⁠091","description":"The adverse reactions observed in KEYNOTE⁠-⁠091 were generally similar to those occurring in other patients with NSCLC receiving KEYTRUDA as a single agent, with the exception of hypothyroidism (22%), hyperthyroidism (11%), and pneumonitis (7%). Two fatal adverse reactions of myocarditis occurred. "}`
    ]
}

const indicationId = 33;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: '1L Combo Therapy – Squamous', url: '/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy/' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/safety/adverse-reactions/nsclc-first-line-monotherapy/' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/safety/adverse-reactions/nsclc-second-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/safety/adverse-reactions/nsclc-perioperative-regimen/' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;091'} 
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                            <p>The safety of KEYTRUDA as a single agent was investigated in KEYNOTE&#8288;-&#8288;091, a multicenter, randomized (1:1), triple-blind, placebo-controlled trial in patients with completely resected stage IB (T2a ≥4 cm), II, or IIIA NSCLC; adjuvant chemotherapy up to 4 cycles was optional. A total of 1,161 patients received KEYTRUDA 200&nbsp;mg (n=580) or placebo (n=581) every 3 weeks. Patients were ineligible if they had active autoimmune disease, were on chronic immunosuppressive agents or had a history of interstitial lung disease or pneumonitis.</p>
                            <p>The median duration of exposure to KEYTRUDA was 11.7 months (range: 1 day to 18.9 months). Sixty-eight percent of patients in the KEYTRUDA arm were exposed to KEYTRUDA for ≥6 months.</p>
                            <p>The adverse reactions observed in KEYNOTE&#8288;-&#8288;091 were generally similar to those occurring in other patients with NSCLC receiving KEYTRUDA as a single agent, with the exception of hypothyroidism (22%), hyperthyroidism (11%), and pneumonitis (7%). Two fatal adverse reactions of myocarditis occurred.</p>
                            </TextBlock>
                        </PageSection>
                    </TemplateColumn>
                {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
